import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import "../../assets/camera.sass"
import Carousel from 'react-bootstrap/Carousel'

const Painting = () => (
  <div className="Painting-component">
    <SEO title="Painting" />
    <div className="service-header">
      <h1 className="camera-scoping-header">Painting</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>
      Experience a world of color and craftsmanship with our professional painting services. Whether it's reviving your home with vibrant interior painting, refreshing decks and fences, or customizing unique pieces, our skilled team ensures flawless finishes every time. 
      Elevate your space with our meticulous attention to detail and commitment to quality in every stroke.
      </p>
    </div>




    <div className="sewer-scoping-images-statement-wrapper">    

      <Carousel>
        <Carousel.Item interval={2500}>
          <div className="scoping-images">
            <Image filename="painting.png"/>
          </div>
        </Carousel.Item>
      </Carousel>

    </div>


  </div>
)

export default Painting
